@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *:focus {
    @apply outline-0;
  }
  html {
    @apply text-gray-800
  }
}

@layer components {
  /* Custom Scroll */
  .custom-scroll::-webkit-scrollbar {
    @apply w-2 h-2;
  }
  .custom-scroll::-webkit-scrollbar-thumb {
    @apply bg-[#babac0] rounded-full;
  }
  .custom-scroll::-webkit-scrollbar-button {
    @apply hidden;
  }

  .hidden-scroll::-webkit-scrollbar {
    @apply hidden;
  }
}

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}